<script>
import AnimationEffects from "./animation-effects";
import AnimationFirework from "./animation-firework";
import AnimationBaseFunctions from "./animation-base-functions";
import AnimationRewardSpin from "./animation-reward-spin";
import AnimationNumberIncrease from "@/components/animation/animation-number-increase";

export default {
  name: "AnimationKingWin",
  mixins: [
    AnimationEffects,
    AnimationFirework,
    AnimationBaseFunctions,
    AnimationRewardSpin,
    AnimationNumberIncrease,
  ],
  data() {
    return {
      unsubscribeEventsKingWin: [],
      destroyAppKingWin: false,
    };
  },

  watch: {
    destroyAppKingWin: function (newVal) {
      if (newVal) {
        this.destroyAll();
      }
    },
  },
  beforeDestroy() {
    this.destroyAll();
  },
  methods: {
    destroyAll() {
      for (let i = 0; i < this.unsubscribeEventsKingWin.length; i++) {
        this.unsubscribeEventsKingWin[i]();
      }

      this.destroyAppKingWin = false;
    },
    AnimationKingWin: function (data) {
      try {
        if (!this.runningAllowed()) {
          return;
        }

        this.destroyAppKingWin = false;

        const timeline = this.gsap.timeline({ paused: true });
        this.unsubscribeEventsKingWin.push(() => {
          timeline.kill();
        });
        const animationDuration = 9; //seconds
        const queueName = "animation-king-win";
        const animationContainer = this.createAnimationContainer();

        let element = document.createElement("gsap-king-win");
        animationContainer.appendChild(element);

        //calc center position
        let calcObj = this.calcGridPosition(1, 0, element);
        let x = calcObj.x;
        let y = calcObj.y;
        let scale_val = calcObj.scale_val;

        //spinning shining light
        let light = document.createElement("img");
        light.classList.add("light");
        light.classList.add("to-center-abs");
        light.src = require("@/assets/img/backgrounds/lobby-glow.png");
        element.appendChild(light);

        //background shield
        let bg_shield = document.createElement("img");
        bg_shield.classList.add("bg");
        bg_shield.classList.add("to-center-abs");
        bg_shield.src = require("@/assets/img/animation/full-accomplishment/levelup-shield.png");
        element.appendChild(bg_shield);

        //main icon
        let context_icon = document.createElement("img");
        context_icon.classList.add("context-icon");
        context_icon.classList.add("to-center-abs");
        context_icon.classList.add("gold");
        element.appendChild(context_icon);

        //banner container
        let bannerContainer = document.createElement("div");
        bannerContainer.classList.add("banner-container");
        bannerContainer.classList.add("to-center-abs");
        element.appendChild(bannerContainer);

        //big banner
        let bigBanner = document.createElement("img");
        bigBanner.classList.add("w-100");
        bigBanner.src = require("@/assets/img/animation/full-reward-earned/reward-show-ani-banner-5-4-x@3x.png");
        bannerContainer.appendChild(bigBanner);

        //badge text
        let bannerText = document.createElement("div");
        bannerText.classList.add("banner-text");
        bannerText.classList.add("to-center-abs");
        bannerText.classList.add("yellow-text");
        bannerText.innerHTML = "Jackpot";
        bannerContainer.appendChild(bannerText);

        //badge container
        let badgeContainer = document.createElement("div");
        badgeContainer.classList.add("badge-container");
        badgeContainer.classList.add("to-center-abs");
        element.appendChild(badgeContainer);

        //badge bg
        let badge_bg = document.createElement("img");
        badge_bg.classList.add("w-100");
        badge_bg.classList.add("to-center-abs");
        badge_bg.src = require("@/assets/img/loyalty/badge-large_sign.png");
        badgeContainer.appendChild(badge_bg);

        //badge icon
        let badge_icon = document.createElement("img");
        badge_icon.classList.add("badge-icon");
        badge_icon.classList.add("to-center-abs");
        badge_icon.src = require("@/assets/img/common/crown6.png");
        badgeContainer.appendChild(badge_icon);

        //amount container
        let amountContainer = document.createElement("div");
        amountContainer.classList.add("amount-container");
        amountContainer.classList.add("to-center-abs");
        element.appendChild(amountContainer);

        //amount text
        let amountText = document.createElement("div");
        amountText.classList.add("yellow-text");
        amountText.classList.add("amount-text-container");
        amountText.classList.add("to-center-abs");
        amountContainer.appendChild(amountText);

        //amount text shadow
        let amountTextShadow = document.createElement("div");
        amountTextShadow.classList.add("amount-text-shadow");
        amountTextShadow.classList.add("to-center-abs");
        amountContainer.appendChild(amountTextShadow);

        //amount icon
        let amountIcon = document.createElement("div");
        amountIcon.classList.add("amount-icon");
        amountIcon.classList.add("to-center-abs");
        amountContainer.appendChild(amountIcon);
        this.AnimationRewardSpinAnimate(
          data.amount_current.toString().length * 100 + 100,
          0,
          data.currency,
          amountIcon
        );

        //glow effect
        let glowEffect = document.createElement("div");
        glowEffect.classList.add("glow-effect");
        element.appendChild(glowEffect);

        //default sound
        let begin_sound = require("@/assets/sound/animation/kingwin/KingWin-animation-background-sound.wav");

        let rewardContainer = animationContainer.getElementsByClassName(
          "animation-reward-container"
        )[0];

        this.gsap.set(element, {
          x: x,
          y: y,
          opacity: 1,
        });

        //glow effect timeline
        const timelineGlow = this.gsap.timeline();
        this.unsubscribeEventsKingWin.push(() => {
          timelineGlow.kill();
        });
        timelineGlow
          .fromTo(
            glowEffect,
            0.2,
            {
              scale: 0,
            },
            {
              scale: 2,
              ease: "linear",
              onStart: () => {
                this.AnimationEffectsExplosionParticle(315, 360, element);
              },
            },
            0.1
          )
          .to(glowEffect, 0.2, {
            scale: 0.5,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            delay: 0.3,
            scale: 1.5,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 0.3,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 1,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 0.3,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 0.9,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 0.3,
            ease: "linear",
          })
          .to(glowEffect, 0.2, {
            scale: 0.8,
            ease: "linear",
            onComplete: () => {
              const particleContainer =
                document.getElementsByClassName("particle-container");
              this.gsap.to(particleContainer, 1, {
                opacity: 0,
                ease: "linear",
              });
            },
          })
          .to(glowEffect, 0.4, {
            scale: 0.2,
            opacity: 0,
            ease: "linear",
          });

        //glow effect timeline
        const timelineFirework = this.gsap.timeline();
        this.unsubscribeEventsKingWin.push(() => {
          timelineFirework.kill();
        });
        timelineFirework
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  330,
                  140,
                  false,
                  element,
                  20,
                  "small-firework"
                );
                this.AnimationFireworkAnimateFireworkBlue(
                  130,
                  300,
                  false,
                  element
                );
                this.AnimationFireworkAnimateFireworkBlue(
                  530,
                  300,
                  false,
                  element
                );
              },
            },
            2
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkBlue(
                  330,
                  180,
                  false,
                  element
                );
              },
            },
            2.2
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  50,
                  50,
                  false,
                  element,
                  24,
                  "small-firework"
                );
              },
            },
            3.5
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  250,
                  50,
                  false,
                  element,
                  24,
                  "small-firework"
                );
              },
            },
            3.7
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  450,
                  50,
                  false,
                  element,
                  24,
                  "small-firework"
                );
              },
            },
            3.9
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkYellow(
                  650,
                  50,
                  false,
                  element,
                  24,
                  "small-firework"
                );
              },
            },
            4.1
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkBlueChain(
                  300,
                  150,
                  false,
                  element
                );
              },
            },
            3.6
          )
          .to(
            element,
            0.01,
            {
              onStart: () => {
                this.AnimationFireworkAnimateFireworkBlue(
                  340,
                  360,
                  false,
                  element,
                  24,
                  "big-firework"
                );
              },
            },
            4
          );

        //background
        let background_darken;
        timeline.to(element, 0.01, {
          onStart: () => {
            background_darken = this.createBackgroundElement();
            //show container and dark background
            timeline.to(
              background_darken,
              0,
              {
                opacity: 1,
              },
              0
            );
          },
        });

        //show container and dark background
        timeline.to(
          animationContainer,
          0,
          {
            opacity: 1,
            pointerEvents: "auto",
          },
          0
        );

        //sound begin
        timeline.to(
          element,
          0.01,
          {
            onStart: () => {
              this.playSoundEffect(begin_sound, false, 0.3);
            },
          },
          0
        );

        //pop in container
        timeline.fromTo(
          bg_shield,
          0.8,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: scale_val,
          },
          1
        );

        //pop in banner and badge
        timeline.fromTo(
          [bannerContainer, badgeContainer],
          0.8,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: scale_val,
          },
          2
        );

        //pop in main icon
        timeline.fromTo(
          context_icon,
          0.8,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            scale: scale_val,
          },
          2.5
        );

        //show reward amount
        timeline.fromTo(
          amountContainer,
          0.8,
          {
            opacity: 0,
            y: 150,
          },
          {
            opacity: 1,
            y: 0,
            onStart: () => {
              this.AnimationNumberIncreaseAnimate(
                amountText,
                data.amount_current / 2,
                data.amount_current,
                3,
                "+"
              );
              this.AnimationNumberIncreaseAnimate(
                amountTextShadow,
                data.amount_current / 2,
                data.amount_current,
                3,
                "+"
              );
            },
          },
          3
        );

        // pop out container.
        timeline.to(
          element,
          0.2,
          {
            ease: "linear.inOut",
            scale: 0,
          },
          animationDuration
        );

        if (rewardContainer) {
          // pop out container.
          timeline.to(
            rewardContainer,
            0.2,
            {
              opacity: 0,
            },
            animationDuration
          );
        }

        // remove blur
        timeline.to(
          element,
          0.01,
          {
            onStart: () => {
              this.removeBackgroundElement();
            },
          },
          animationDuration
        );
        timeline.to(
          element,
          0,
          {
            onComplete: () => {
              if (animationContainer.parentNode) {
                animationContainer.parentNode.removeChild(animationContainer);
              }
              this.removeFromQueue(queueName);
              this.destroyAppKingWin = true;
            },
          },
          animationDuration + 1
        );

        //add animation to queue
        this.addToQueue(timeline, queueName);
      } catch (e) {
        console.error("AnimationKingWin konnte nicht geladen werden", e);
        this.skipAnimationFull();
      }
    },
  },
};
</script>

<style lang="scss">
gsap-king-win {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  margin-left: -320px;
  margin-top: -360px;
  width: 640px;
  height: 640px;
  opacity: 0;

  .bg {
    position: absolute;
    top: 23%;
    left: 0;
    width: 100%;
    z-index: 5;
  }

  .context-icon {
    z-index: 30;
    background-size: contain;
    padding-top: 66.64%;

    &.gold {
      top: 21%;
      background: url(~@/assets/img/common/goldstack_L.png) center/100%
        no-repeat;
      width: 40%;
      height: 0;
    }
  }

  .light {
    position: absolute;
    top: -10%;
    width: 140%;
    left: -20%;
    z-index: 20;
    opacity: 0.1;
    animation: spinLight 7s infinite;
    animation-timing-function: linear;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;

    @keyframes spinLight {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .banner-container {
    top: 62%;
    z-index: 20;
    width: 100%;

    .banner-text {
      top: 5%;
      z-index: 30;
      font-size: 80px;
      text-align: center;
      font-family: cinzel-decorative-black;
    }
  }

  .badge-container {
    top: 76%;
    z-index: 10;
    width: 30%;

    .badge-icon {
      width: 62%;
      margin-top: 15%;
    }
  }

  .amount-container {
    top: 15%;
    z-index: 10;
    width: 120%;
    left: -10%;
    text-align: center;

    .amount-text-container {
      font-size: 120px;
      font-family: Ubuntu-Bold;
      z-index: 21;
      margin-left: -80px;
    }

    .amount-text {
      font-size: 120px;
      font-family: Ubuntu-Bold;
      z-index: 21;
      margin-left: -140px;
    }
    .amount-text-shadow {
      font-size: 124px;
      font-family: Ubuntu-Bold;
      z-index: 20;
      margin-left: -87px;
    }

    .amount-icon {
      position: absolute;
      margin-top: 6%;
      transform: scale(0.45);
      width: 100px;
      height: 100px;
    }
  }

  .glow-effect {
    position: absolute;
    top: calc(50% - 160px);
    left: calc(50% - 200px);
    width: 400px;
    height: 400px;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 60px 30px #fff, 0 0 140px 90px #0ff;
    opacity: 0.7;
    z-index: 2;
    transform: scale(0);
  }

  .small-firework {
    transform: scale(0.5);
  }
  .big-firework {
    transform: scale(2);
  }
}
</style>
