<script>
import crown_spritesheet from "./json/kleinekrone-anim-spritesheet-crownflip-WEB";
import coin_spritesheet from "./json/kleinekrone-spritesheet-coinspin-web";
import AnimationEffects from "./animation-effects";
import AnimationBaseFunctions from "./animation-base-functions";

export default {
  name: "AnimationRewardSpin",
  mixins: [AnimationEffects, AnimationBaseFunctions],
  methods: {
    animationRewardSpinDoAnimationCard: function (
      x,
      y,
      card,
      custom_class,
      parentElement,
      probabilities
    ) {
      let body = document.body;
      let element = document.createElement("gsap-reward-spin");
      element.classList.add(custom_class);

      if (parentElement) {
        parentElement.appendChild(element);
      } else {
        body.appendChild(element);
      }

      this.gsap.set(element, {
        x: x,
        y: y,
      });

      let cardObj = this.createCard(card);
      element.appendChild(cardObj.col);

      const timeline = this.gsap.timeline({ ease: "power1" });
      this.$store.commit("animations/addUnsubscribeEvents", () => {
        timeline.kill();
      });
      this.gsap.set(cardObj.front, {
        rotationY: 180,
        transformOrigin: "center bottom",
      });
      this.gsap.set(cardObj.back, {
        rotationY: 0,
        transformOrigin: "center bottom",
      });
      this.gsap.set(cardObj.icon, { scale: 0 });

      let flip_time = this.getTimeFromRarity(
        card.probability.probability,
        probabilities
      );
      let flip_degree = flip_time * 360 * 2;

      /*ANIMATION BEGINS*/
      timeline.to(
        cardObj.col,
        2,
        {
          opacity: 1,
        },
        0
      );

      timeline
        .to(cardObj.front, flip_time, { rotationY: "+=" + flip_degree + "" }, 0)
        .to(cardObj.back, flip_time, { rotationY: "+=" + flip_degree + "" }, 0)
        .to(
          cardObj.emptyOverlay,
          2,
          {
            opacity: 0,
          },
          flip_time * 0.2
        )
        .to(
          cardObj.icon,
          0.01,
          {
            onStart: () => {
              this.AnimationEffectsAnimateSmoke(165, 220, false, element);
            },
          },
          flip_time - 1.5
        )
        .fromTo(
          cardObj.icon,
          1,
          {
            scale: 0,
          },
          {
            ease: "elastic",
            transformOrigin: "center 100px",
            scale: 1,
          },
          flip_time - 0.2
        );

      return timeline;
    },

    createCard: function (card) {
      let col = document.createElement("div");
      col.classList.add("item");

      //create card front side
      let front_side = document.createElement("div");
      front_side.classList.add("cardFront");
      let img = document.createElement("img");
      img.src = require("@/assets/img/animation/full-reward-earned/cardshow-backside.png");
      front_side.appendChild(img);
      col.appendChild(front_side);

      //create card back side
      let back_side = document.createElement("div");
      back_side.classList.add("cardBack");

      //create card background
      let background = document.createElement("img");
      background.src = require(`@/assets/img/cards/card-background_${card.probability.probability}.png`);
      back_side.appendChild(background);

      //create an overlay to hide the card back
      let emptyOverlay = document.createElement("img");
      emptyOverlay.src = require(`@/assets/img/cards/card-empty_${card.probability.probability}.png`);
      emptyOverlay.classList.add("empty-overlay");
      back_side.appendChild(emptyOverlay);

      //create card icon
      let icon = document.createElement("img");
      icon.src = card.image_urls.image_url;
      back_side.appendChild(icon);
      col.appendChild(back_side);

      return {
        col: col,
        front: front_side,
        back: back_side,
        icon: icon,
        emptyOverlay: emptyOverlay,
      };
    },

    getTimeFromRarity: function (rarity, probabilities) {
      const durations = [6, 5, 5, 4, 3];
      for (let i = 0; i < probabilities.length; i++) {
        if (rarity === probabilities[i].probability) {
          return durations[i];
        }
      }
    },

    getMaxCardsFromRarity: function (rarity) {
      return rarity + 1;
    },

    animationRewardSpinDoAnimationOthers: function (
      x,
      y,
      json_arr,
      custom_class,
      parentElement
    ) {
      let steps = json_arr.length - 1;
      let body = document.body;
      let element = document.createElement("gsap-reward-spin");
      element.classList.add(custom_class);

      if (parentElement) {
        parentElement.appendChild(element);
      } else {
        body.appendChild(element);
      }

      element.frame = 0;

      this.gsap.set(element, {
        x: x,
        y: y,
      });

      let duration = steps / 24;
      let tween = this.gsap.to(element, duration, {
        frame: steps,
        ease: `steps(${steps})`,
        onComplete: () => {
          tween.restart();
        },
        modifiers: {
          frame: (i, target) => {
            let sprite = json_arr[Math.round(i)].frame;
            let rotated = json_arr[Math.round(i)].rotated;

            //set background images
            this.gsap.set(target, {
              xPercent: -50,
              yPercent: -50,
              backgroundImage:
                'url("' +
                require("@/assets/img/animation/reward-spin/" +
                  json_arr[Math.round(i)].image) +
                '")',
            });

            if (rotated) {
              this.gsap.set(target, {
                rotation: "-90deg",
                transformOrigin: "center center",
              });
              target.style.backgroundPosition =
                -sprite.x + "px " + -sprite.y + "px";
              target.style.width = sprite.h + "px";
              target.style.height = sprite.w + "px";
            } else {
              this.gsap.set(target, {
                rotation: "0deg",
              });
              target.style.backgroundPosition =
                -sprite.x + "px " + -sprite.y + "px";
              target.style.width = sprite.w + "px";
              target.style.height = sprite.h + "px";
            }

            return i;
          },
        },
      });

      this.$store.commit("animations/addUnsubscribeEvents", () => {
        tween.kill();
      });
    },

    AnimationRewardSpinAnimate: function (
      x,
      y,
      rewardType,
      parentElement,
      card,
      probabilities
    ) {
      let custom_class = "";
      let json_arr = "";
      switch (rewardType.toLowerCase()) {
        case "crown":
          let crownElement = document.createElement("img");
          crownElement.src = require("@/assets/img/common/crown2_big.png");
          crownElement.classList.add("temp-crown");
          crownElement.classList.add("to-center-abs");
          if (parentElement) {
            parentElement.appendChild(crownElement);
          }
          break;

        case "card":
          custom_class = "spin-card";
          this.animationRewardSpinDoAnimationCard(
            x,
            y,
            card,
            custom_class,
            parentElement,
            probabilities
          );
          break;
        case "gold":
          let goldElement = document.createElement("img");
          goldElement.src = require("@/assets/img/common/coin1_big.png");
          goldElement.classList.add("temp-gold");
          goldElement.classList.add("to-center-abs");
          if (parentElement) {
            parentElement.appendChild(goldElement);
          }
          break;
        case "loyalty":
          let element = document.createElement("img");
          element.src = require("@/assets/img/nav-menu/nav-icon-loyalty.png");
          element.classList.add("temp-loyalty");
          element.classList.add("to-center-abs");

          if (parentElement) {
            parentElement.appendChild(element);
          }
          break;
        case "elixir":
          let element2 = document.createElement("img");
          element2.src = require("@/assets/img/common/elixir.png");
          element2.classList.add("temp-elixir");
          element2.classList.add("to-center-abs");

          if (parentElement) {
            parentElement.appendChild(element2);
          }
          break;
        case "expbooster":
          let element3 = document.createElement("img");
          element3.src = require("@/assets/img/shop/package-254.png");
          element3.classList.add("temp-expboost");
          element3.classList.add("to-center-abs");

          if (parentElement) {
            parentElement.appendChild(element3);
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss">
gsap-reward-spin {
  display: block;
  position: absolute;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  pointer-events: none;

  &.spin-card {
    width: 56%;
    height: 200px;
    top: 11%;
    position: absolute;
    left: 3.5%;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    .cardCont {
      float: left;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
    }

    .cardFront,
    .cardBack {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;

      img {
        z-index: 99;
        position: absolute;
        width: 100%;
        height: auto;
        padding: 5%;
      }
    }
  }

  .item {
    perspective: 99999px;
  }
}

.temp-loyalty {
  width: 110px;
  z-index: 9;
  top: 180px;
}
.temp-gold {
  width: 110px;
  height: 110px;
  z-index: 9;
  top: 180px;
}
.temp-crown {
  width: 110px;
  height: 83px;
  z-index: 9;
  top: 180px;
}
.temp-elixir {
  width: 170px;
  z-index: 9;
  top: 120px;
}
.temp-expboost {
  width: 110px;
  height: 83;
  z-index: 9;
  top: 180px;
}
</style>
