<template><div></div></template>
<script>
export default {
  name: "AnimationSpineBaseFunctions",
  data() {
    return {
      pixiUtils: null,
      pixiApp: null,
    };
  },

  methods: {
    //create pixi canvas
    createCanvas: async function (options, assets, containerId) {
      const { Application } = await import("@pixi/app");
      const utils = await import("@pixi/utils");
      return new Promise((resolve) => {
        this.pixiUtils = utils;
        this.pixiApp = Application;
        this.pixiUtils.clearTextureCache();
        this.pixiUtils.destroyTextureCache();

        const app = new this.pixiApp(options);
        let container = document.body;
        if (containerId) {
          container = document.getElementById(containerId);
        }
        container.appendChild(app.view);
        assets.forEach((asset) => {
          app.loader.add(asset[0], asset[1]);
        });
        resolve(app);
      });
    },

    //remove canvas from container
    removePrevCanvas: function (containerId) {
      const container = document.getElementById(containerId);
      const canvas = container.getElementsByTagName("canvas");
      if (canvas.length > 1) {
        container.removeChild(canvas[0]);
      }
    },

    //get important spine data from pixi loader
    getSpineData: function (res) {
      let spines = [];
      for (let key in res) {
        if (res[key].spineData) {
          res[key].spineData.name = res[key].name;
          spines.push(res[key].spineData);
        }
      }
      return spines;
    },

    setSkinByName: function (skeleton, skinName) {
      skeleton.setSkin(null);
      skeleton.setSkinByName(skinName);
    },

    getAnimationByName: function (spineData, name) {
      let animation;
      for (let i = 0; i < spineData.animations.length; i++) {
        if (spineData.animations[i].name === name) {
          animation = spineData.animations[i];
          break;
        }
      }
      return animation;
    },

    setupSpineAudio(spine) {
      spine.state.onEvent = (i, event) => {
        let src = this.assetPath + event.data.audioPath;
        this.playSoundEffect(src, false, 0.5);
      };
    },

    destroyAll: function (app) {
      // destroy app contexts
      while (app.stage.children[0]) {
        app.stage.children[0].destroy(true);
      }

      app.loader.reset();
      app.loader.destroy(true);

      //destroy canvas context
      const contextIds = ["bitmaprenderer", "2d", "webgl", "webg2"];
      for (let i = 0; i < contextIds.length; i++) {
        let gl = app.view.getContext(contextIds[i]);
        if (gl) {
          const buf = gl.createBuffer();
          gl.bindBuffer(gl.ARRAY_BUFFER, buf);
          const numAttributes = gl.getParameter(gl.MAX_VERTEX_ATTRIBS);
          for (let attrib = 0; attrib < numAttributes; ++attrib) {
            gl.vertexAttribPointer(attrib, 1, gl.FLOAT, false, 0, 0);
          }

          const numTextureUnits = gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS);
          for (let unit = 0; unit < numTextureUnits; ++unit) {
            gl.activeTexture(gl.TEXTURE0 + unit);
            gl.bindTexture(gl.TEXTURE_2D, null);
            gl.bindTexture(gl.TEXTURE_CUBE_MAP, null);
          }

          gl.canvas.width = 1;
          gl.canvas.height = 1;

          let ext = gl.getExtension("WEBGL_lose_context");
          if (ext) {
            ext.loseContext();
          }
        }
      }

      app.destroy(true);

      this.pixiUtils.clearTextureCache();
      this.pixiUtils.destroyTextureCache();
    },
  },
};
</script>

<style lang="scss"></style>
